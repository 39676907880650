<template>
	<div>
		<div class="navbar">
			<div class="container">
				<div class="row-between nav-style">
					<div class="nav-logo" @click="toIndex">
						<img src="../assets/img/logo.png" alt="" />
					</div>
					<div class="nav-detail row-between">
						<div class="nav-li" :class="path == item.path ? 'nav-li-sp' : ''" v-for="(item, index) in nav" :key="index">
							<router-link :to="item.path" @click.native="changePath(item.path)"><img src="../assets/img/homepage.png" class="home-icon"
								 v-if="index == 0" />{{ item.name }}</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main">
			<router-view></router-view>
		</div>
		<div class="footer">
			<div class="container fot-top">
				<div class="to-top arrow-spe" @click="toTop">
					<img src="../assets/img/to-top.png" alt="" />
				</div>
				<div class="row-between" style="align-items: flex-end">
					<div class="fot-connect">
						<div style="margin-bottom: 40px">
							<div class="fot-connect-t1">
								<span class="item-color">01.</span>客服电话
							</div>
							<div class="fot-connect-t2">021-6082-8366</div>
							<div class="fot-connect-t3">服务时间为：周一至周五8:30-17:00</div>
						</div>
						<div class="row-between">
							<div>
								<div class="fot-connect-t1">
									<span class="item-color">02.</span>企业合作
								</div>
								<div class="fot-connect-t3">bd@squirrelf.com</div>
							</div>
							<div>
								<div class="fot-connect-t1">
									<span class="item-color">03.</span>广告招商
								</div>
								<div class="fot-connect-t3">ad@squirrelf.com</div>
							</div>
							<div>
								<div class="fot-connect-t1">
									<span class="item-color">04.</span>公司地址
								</div>
								<div class="fot-connect-t3">
									上海市杨浦区国权北路 1688 弄 24 号（c6 标下沉式广场）
								</div>
							</div>
						</div>
					</div>
					<div class="fot-qrcode row-around" style="align-items: flex-end">
						<div>
							<img src="../assets/img/qrcode-1.png" alt="" />
							<div class="fot-qrcode-t1">小松鼠服务号</div>
						</div>
						<div>
							<img src="../assets/img/qrcode-2.png" alt="" />
							<div class="fot-qrcode-t1">小松鼠订阅号</div>
						</div>
						<div>
							<img src="../assets/img/qrcode-3.png" alt="" />
							<div class="fot-qrcode-t1">微信小程序</div>
						</div>
					</div>
				</div>
			</div>
			<div class="fot-copy">2019版权所有© 小松鼠(上海)环保科技有限公司</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				nav: [{
						id: 1,
						path: "/homepage",
						name: "首页"
					},
					{
						id: 2,
						path: "/municipal",
						name: "市政项目"
					},
					{
						id: 3,
						path: "/hardware",
						name: "硬件产品"
					},
					{
						id: 4,
						path: "/software",
						name: "软件产品"
					},
					{
						id: 5,
						path: "/service",
						name: "服务"
					},
					{
						id: 6,
						path: "/cooperation",
						name: "合作案例"
					},
					// { id: 7, path: "/poolplan", name: "全国联营计划" },
					{
						id: 8,
						path: "/advertisement",
						name: "广告合作"
					},
				],
				path: "",
			};
		},

		mounted: function() {
			this.getPath();
		},
		updated() {
			window.scroll(0, 0);
		},
		methods: {
			changePath(e) {
				this.path = e;
			},
			getPath() {
				this.path = this.$route.path;
			},
			toIndex() {
				this.$router.push({
					path: "/homepage"
				});
				this.path = "/homepage"
			},
			//回到顶部

			toTop() {
				let top = document.documentElement.scrollTop || document.body.scrollTop;
				// 实现滚动效果
				const timeTop = setInterval(() => {
					document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
					if (top <= 0) {
						clearInterval(timeTop);
					}
				}, 10);
			},
		},
	};
</script>

<style>
	.navbar {
		width: 100%;
		background: #ffffff;
		box-shadow: 0px 0px 10px 0px rgba(51, 51, 51, 0.2);
		height: 88px;
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0.98;
		z-index: 999;
	}

	.nav-style {
		height: 88px;
	}

	.nav-logo {
		width: 180px;
		height: 75px;
		cursor: pointer;
	}

	.nav-detail {
		width: 855px;
	}

	.nav-li {
		width: 106px;
		font-size: 14px;
		/* font-family: MicrosoftYaHei; */
		font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
		color: #333333;
		text-align: center;
		font-weight: 600;
		position: relative;
		line-height: 88px;
	}

	.nav-li a {
		/* font-family: MicrosoftYaHei; */
		font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
		display: block;
		width: 106px;
		line-height: 88px;
	}

	.home-icon {
		display: inline-block;
		width: 24px;
		height: 25px;
		vertical-align: middle;
		margin-top: -3px;
	}

	.nav-li::before {
		content: "";
		display: block;
		width: 0;
		height: 4px;
		background: linear-gradient(90deg, #0285ff 0%, #02aafe 100%);
		position: absolute;
		bottom: -1px;
		left: 0;
		transition: all 0.2s linear;
	}

	.nav-li:hover a {
		color: #0285ff;
	}

	.nav-li:hover:before {
		width: 106px;
		transition: all 0.2s linear;
	}

	.nav-li:not(:last-child):after {
		content: "";
		display: block;
		width: 1px;
		height: 20px;
		background: #f1f1f1;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -10px;
	}

	.nav-li-sp a {
		font-family: "PingFang SC", Arial, "Microsoft YaHei", sans-serif;
		color: #0285ff;
	}

	.nav-li-sp::before {
		content: "";
		display: block;
		width: 106px;
		height: 4px;
		background: linear-gradient(90deg, #0285ff 0%, #02aafe 100%);
		position: absolute;
		bottom: -1px;
		left: 0;
	}

	.main {
		min-height: 55.6vh;
		background: #fff;
		padding-top: 88px;
	}

	.fot-copy {
		line-height: 60px;
		height: 60px;
		background: #010123;
		font-size: 14px;
		font-family: MicrosoftYaHeiLight,"PingFang SC";
		color: #ffffff;
		text-align: center;
	}

	.footer {
		background: #000032;
		color: rgba(255, 255, 255, 0.9);
		font-weight: 300;
	}

	.fot-top {
		padding: 70px 0;
		position: relative;
	}

	.to-top {
		position: absolute;
		right: 0;
		top: -24px;
		width: 48px;
		height: 48px;
		cursor: pointer;
	}

	.fot-connect {
		width: 60%;
	}

	.fot-connect-t1 {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 20px;
	}

	.fot-connect-t2 {
		font-size: 40px;
		font-weight: bold;
		margin: 16px 0 20px;
	}

	.fot-qrcode {
		width: 33.3%;
	}

	.fot-qrcode-t1 {
		text-align: center;
		margin-top: 10px;
	}
</style>
